/* Estilos específicos para la página de comercios */

/* Hero Section */
.merchant-hero {
    padding: 10rem 0 7rem;
    background-color: var(--light-color);
    position: relative;
    overflow: hidden;
}

.merchant-hero::before {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(183, 255, 51, 0.1), rgba(219, 255, 153, 0.1));
    top: -200px;
    right: -200px;
    z-index: 0;
}

.merchant-hero .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.merchant-hero-content {
    max-width: 600px;
    position: relative;
    z-index: 1;
}

.merchant-hero h1 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    color: var(--dark-color);
}

.merchant-hero-description {
    font-size: 1.25rem;
    color: var(--gray-700);
    margin-bottom: 2.5rem;
    line-height: 1.6;
}

.merchant-hero-cta {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.merchant-hero-image {
    width: 45%;
    max-width: 500px;
    position: relative;
    z-index: 1;
}

/* Merchant Benefits */
.merchant-benefits {
    padding: 6rem 0;
    background-color: white;
}

.merchant-benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 3rem;
}

.merchant-benefit-card {
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 2.5rem;
    text-align: center;
    transition: var(--transition);
    border: 1px solid var(--gray-200);
}

.merchant-benefit-card:hover {
    transform: translateY(-10px);
    box-shadow: var(--box-shadow);
}

.merchant-benefit-card .benefit-icon {
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.merchant-benefit-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--dark-color);
}

.merchant-benefit-card p {
    color: var(--gray-600);
    line-height: 1.6;
}

/* Merchant Guide */
.merchant-guide {
    padding: 6rem 0;
    background-color: var(--light-color);
    position: relative;
}

.merchant-guide::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(199, 54, 54, 0.05), rgba(165, 41, 41, 0.05));
    bottom: -100px;
    left: -100px;
    z-index: 0;
}

.guide-subtitle {
    text-align: center;
    font-size: 1.25rem;
    max-width: 700px;
    margin: -2rem auto 4rem;
    color: var(--gray-700);
}

.guide-steps {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.guide-step {
    margin-bottom: 3rem;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.guide-step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 70px;
    left: 35px;
    height: calc(100% + 3rem);
    width: 2px;
    background: linear-gradient(to bottom, var(--secondary-color), rgba(183, 255, 51, 0.2));
}

.step-number {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--gradient-green);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
    color: var(--dark-color);
    margin-right: 2rem;
    flex-shrink: 0;
    box-shadow: 0 10px 20px rgba(183, 255, 51, 0.3);
}

.step-content {
    flex: 1;
}

.step-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--dark-color);
}

.step-content p {
    color: var(--gray-600);
    line-height: 1.6;
    font-size: 1.1rem;
}

.step-image {
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    transition: transform 0.3s ease;
}

.step-image:hover {
    transform: scale(1.02);
}

/* Dashboard Preview */
.dashboard-preview {
    padding: 6rem 0;
    background-color: white;
}

.dashboard-subtitle {
    text-align: center;
    font-size: 1.25rem;
    max-width: 700px;
    margin: -2rem auto 4rem;
    color: var(--gray-700);
}

.dashboard-features {
    display: flex;
    align-items: center;
    gap: 4rem;
}

.dashboard-image {
    flex: 1;
    max-width: 50%;
}

.dashboard-image img {
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-image img:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.dashboard-features-list {
    flex: 1;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2.5rem;
    transition: transform 0.3s ease;
}

.feature-item:hover {
    transform: translateX(10px);
}

.feature-item:last-child {
    margin-bottom: 0;
}

.feature-icon {
    font-size: 2.5rem;
    margin-right: 1.5rem;
}

.feature-text h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: var(--dark-color);
}

.feature-text p {
    color: var(--gray-600);
    line-height: 1.6;
}

/* Registration Form */
.merchant-registration {
    padding: 6rem 0;
    background-color: var(--light-color);
    position: relative;
    overflow: hidden;
}

.merchant-registration::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(183, 255, 51, 0.1), rgba(219, 255, 153, 0.1));
    top: -200px;
    left: -200px;
    z-index: 0;
}

.registration-subtitle {
    text-align: center;
    font-size: 1.25rem;
    max-width: 700px;
    margin: -2rem auto 4rem;
    color: var(--gray-700);
}

.registration-form-container {
    max-width: 700px;
    margin: 0 auto;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 3rem;
    box-shadow: var(--box-shadow);
    position: relative;
    z-index: 1;
}

.registration-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: var(--gray-700);
}

.form-group input,
.form-group select,
.form-group textarea {
    padding: 0.75rem 1rem;
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius-sm);
    font-family: var(--font-family);
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--secondary-color);
    box-shadow: 0 0 0 3px rgba(183, 255, 51, 0.2);
}

.form-row {
    display: flex;
    gap: 1.5rem;
}

.form-row .form-group {
    flex: 1;
}

.checkbox-group {
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
}

.checkbox-group input {
    width: 20px;
    height: 20px;
}

/* Testimonials */
.merchant-testimonials {
    padding: 6rem 0;
    background-color: white;
}

.testimonials-slider {
    display: flex;
    gap: 2rem;
    overflow-x: auto;
    padding: 1rem 0.5rem;
    margin: 0 -0.5rem;
    scroll-snap-type: x mandatory;
    scrollbar-width: none; /* Firefox */
}

.testimonials-slider::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.testimonial-card {
    flex: 0 0 calc(33.333% - 1.5rem);
    min-width: 300px;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 2rem;
    box-shadow: var(--box-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    scroll-snap-align: start;
}

.testimonial-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.testimonial-content {
    margin-bottom: 2rem;
}

.testimonial-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--gray-700);
    font-style: italic;
}

.testimonial-content p::before {
    content: '"';
    font-size: 2rem;
    color: var(--secondary-color);
    line-height: 0;
    vertical-align: -0.25em;
    margin-right: 0.25rem;
}

.testimonial-author {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.testimonial-author img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.author-info h4 {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    color: var(--dark-color);
}

.author-info p {
    font-size: 0.9rem;
    color: var(--gray-600);
}

/* Merchant FAQ */
.merchant-faq {
    padding: 6rem 0;
    background-color: var(--light-color);
}

/* Onboarding highlight */
.onboarding-highlight {
    background: linear-gradient(135deg, rgba(183, 255, 51, 0.1), rgba(219, 255, 153, 0.2));
    border-radius: var(--border-radius);
    padding: 1.5rem;
    margin: 1.5rem 0 2rem;
    border-left: 4px solid var(--secondary-color);
}

.onboarding-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.onboarding-highlight h4 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    color: var(--dark-color);
}

.onboarding-highlight p {
    color: var(--gray-700);
    font-size: 1rem;
    margin-bottom: 0;
}

/* Onboarding Banner */
.onboarding-banner {
    background: linear-gradient(135deg, rgba(183, 255, 51, 0.1), rgba(219, 255, 153, 0.2));
    border-radius: var(--border-radius);
    padding: 2.5rem;
    margin: 4rem 0 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-left: 5px solid var(--secondary-color);
}

.onboarding-banner .onboarding-icon {
    font-size: 4rem;
    flex-shrink: 0;
}

.onboarding-content h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: var(--dark-color);
}

.onboarding-content p {
    color: var(--gray-700);
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 0;
}

/* Pricing Section */
.merchant-pricing {
    padding: 6rem 0;
    background-color: white;
}

.pricing-subtitle {
    text-align: center;
    font-size: 1.25rem;
    max-width: 800px;
    margin: -2rem auto 4rem;
    color: var(--gray-700);
}

.pricing-container {
    display: flex;
    justify-content: center;
    gap: 2.5rem;
    margin-bottom: 4rem;
}

.pricing-card {
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 3rem;
    flex: 1;
    max-width: 400px;
    box-shadow: var(--box-shadow);
    transition: var(--transition);
    position: relative;
    border: 1px solid var(--gray-200);
}

.pricing-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.pricing-card.premium {
    background: linear-gradient(135deg, #ffffff, #f9fafb);
    border: 2px solid var(--secondary-color);
    z-index: 1;
}

.pricing-badge {
    position: absolute;
    top: -15px;
    right: 30px;
    background: var(--gradient-green);
    color: var(--dark-color);
    font-weight: 600;
    padding: 0.5rem 1.25rem;
    border-radius: 50px;
    font-size: 0.9rem;
}

.pricing-header {
    text-align: center;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--gray-200);
}

.pricing-header h3 {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    color: var(--dark-color);
}

.pricing-price {
    font-size: 3.5rem;
    font-weight: 700;
    color: var(--dark-color);
    line-height: 1;
}

.pricing-period {
    color: var(--gray-600);
    font-size: 1rem;
    margin-top: 0.5rem;
}

.pricing-description {
    text-align: center;
    color: var(--gray-700);
    margin-bottom: 2rem;
    font-size: 1.1rem;
}

.pricing-features {
    margin-bottom: 2.5rem;
}

.pricing-features li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--gray-700);
}

.feature-icon {
    margin-right: 1rem;
    font-size: 1.25rem;
}

.btn-full {
    width: 100%;
    text-align: center;
}

.pricing-note {
    text-align: center;
    margin-top: 1.5rem;
    color: var(--gray-600);
    font-size: 0.95rem;
}

.pricing-guarantee {
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 2.5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    max-width: 900px;
    margin: 0 auto;
    box-shadow: var(--box-shadow);
}

.guarantee-icon {
    font-size: 3.5rem;
    flex-shrink: 0;
}

.guarantee-content h3 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    color: var(--dark-color);
}

.guarantee-content p {
    color: var(--gray-600);
    line-height: 1.6;
    margin-bottom: 0;
}

/* Responsive styles */
@media (max-width: 992px) {
    .merchant-hero .container {
        flex-direction: column;
    }
    
    .merchant-hero-content {
        max-width: 100%;
        text-align: center;
        margin-bottom: 3rem;
    }
    
    .merchant-hero-cta {
        justify-content: center;
    }
    
    .merchant-hero-image {
        width: 80%;
        max-width: 400px;
        margin: 0 auto;
    }
    
    .dashboard-features {
        flex-direction: column;
    }
    
    .dashboard-image {
        max-width: 100%;
        margin-bottom: 3rem;
    }
    
    .pricing-container {
        flex-direction: column;
        align-items: center;
    }
    
    .pricing-card {
        max-width: 100%;
        width: 100%;
    }
    
    .onboarding-banner {
        flex-direction: column;
        text-align: center;
        padding: 2rem;
    }
}

@media (max-width: 768px) {
    .merchant-hero h1 {
        font-size: 2.75rem;
    }
    
    .guide-step {
        flex-direction: column;
    }
    
    .step-number {
        margin-bottom: 1.5rem;
        margin-right: 0;
    }
    
    .guide-step::after {
        display: none;
    }
    
    .form-row {
        flex-direction: column;
        gap: 1.5rem;
    }
    
    .pricing-guarantee {
        flex-direction: column;
        text-align: center;
        padding: 2rem;
    }
}

@media (max-width: 576px) {
    .merchant-hero h1 {
        font-size: 2.25rem;
    }
    
    .merchant-hero-description {
        font-size: 1.1rem;
    }
    
    .registration-form-container {
        padding: 2rem 1.5rem;
    }
} 

/* Estilos para páginas legales */
.legal-content {
    padding: 8rem 0 4rem;
    background-color: white;
}

.legal-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 24px;
}

.legal-header {
    margin-bottom: 3rem;
    text-align: center;
}

.legal-header h1 {
    font-size: 2.5rem;
    color: var(--dark-color);
    margin-bottom: 1rem;
}

.legal-header p {
    color: var(--gray-600);
    font-size: 1.1rem;
}

.legal-section {
    margin-bottom: 3rem;
}

.legal-section h2 {
    font-size: 1.75rem;
    color: var(--dark-color);
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--gray-200);
}

.legal-section h3 {
    font-size: 1.35rem;
    color: var(--dark-color);
    margin: 1.5rem 0 1rem;
}

.legal-section p, .legal-section li {
    color: var(--gray-700);
    line-height: 1.7;
    margin-bottom: 1rem;
}

.legal-section ul, .legal-section ol {
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.legal-section ul li, .legal-section ol li {
    margin-bottom: 0.5rem;
}

.legal-section a {
    color: var(--primary-color);
    text-decoration: underline;
}

.legal-section a:hover {
    text-decoration: none;
}

.legal-footer {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid var(--gray-200);
    text-align: center;
    color: var(--gray-600);
}

.legal-footer p {
    margin-bottom: 1rem;
}

.legal-nav {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.legal-nav a {
    color: var(--gray-700);
    transition: var(--transition);
}

.legal-nav a:hover {
    color: var(--primary-color);
}

.last-updated {
    font-style: italic;
    color: var(--gray-500);
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .legal-header h1 {
        font-size: 2rem;
    }
    
    .legal-section h2 {
        font-size: 1.5rem;
    }
    
    .legal-section h3 {
        font-size: 1.25rem;
    }
    
    .legal-nav {
        flex-direction: column;
        gap: 1rem;
    }
} 

/* Base Styles */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
    --primary-color: #c73636; /* Color rojo de Mayah */
    --secondary-color: #b7ff33; /* Verde brillante */
    --accent-color: #FFB84D; /* Naranja */
    --dark-color: #222222; /* Negro suave */
    --light-color: #F9FAFB; /* Gris muy claro */
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --gray-900: #212529;
    --font-family: 'Space Grotesk', sans-serif;
    --border-radius: 12px;
    --border-radius-sm: 8px;
    --box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
    --transition: all 0.3s ease;
    --gradient-green: linear-gradient(180deg, #b7ff33 0%, rgba(219, 255, 153, 1) 100%);
    --gradient-red: linear-gradient(180deg, #c73636 0%, #a52929 100%);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--font-family);
    line-height: 1.6;
    color: var(--gray-800);
    background-color: var(--light-color);
    font-size: 16px;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    transition: var(--transition);
}

a:hover {
    color: var(--dark-color);
}

ul {
    list-style: none;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
}

.section-title {
    font-size: 2.75rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3.5rem;
    color: var(--dark-color);
    line-height: 1.2;
}

/* Button Styles - Actualizados con gradientes */
.btn {
    display: inline-block;
    padding: 0.875rem 1.75rem;
    border-radius: 50px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: var(--transition);
    border: none;
    font-size: 1rem;
    font-family: var(--font-family);
}

.btn-primary {
    background: var(--gradient-green);
    color: var(--dark-color);
    box-shadow: 0 4px 14px rgba(183, 255, 51, 0.3);
}

.btn-outline {
    background-color: white;
    border: 2px solid var(--gray-200);
    color: var(--dark-color);
}

.btn-large {
    padding: 1.125rem 2.25rem;
    font-size: 1.1rem;
}

.btn-small {
    padding: 0.5rem 1.25rem;
    font-size: 0.9rem;
}

/* Header Styles */
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0;
}

.logo img {
    height: 100px;
}

.main-nav ul {
    display: flex;
    gap: 2.5rem;
}

.main-nav a {
    color: var(--gray-700);
    font-weight: 500;
    position: relative;
    padding: 0.5rem 0;
}

.main-nav a:hover {
    color: var(--primary-color);
}

.main-nav a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-color);
    transition: width 0.3s ease;
}

.main-nav a:hover::after {
    width: 100%;
}

.header-buttons {
    display: flex;
    gap: 1rem;
}

.mobile-menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

.mobile-menu-toggle span {
    display: block;
    width: 25px;
    height: 2px;
    margin: 6px 0;
    background-color: var(--gray-700);
    transition: var(--transition);
}

/* Hero Section - Ajustado según el diseño */
.hero {
    padding: 8rem 0 5rem;
    background-color: white;
    position: relative;
    overflow: hidden;
}

.hero .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
}

.hero-content {
    flex: 1;
    max-width: 600px;
}

.hero h1 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 1.5rem;
    color: var(--dark-color);
    letter-spacing: -0.02em;
}

.hero-description {
    font-size: 1.125rem;
    color: var(--gray-600);
    margin-bottom: 2rem;
    line-height: 1.6;
}

.hero-cta {
    display: flex;
    gap: 1rem;
}

.hero-image {
    flex: 1;
    max-width: 500px;
}

.hero-image img {
    width: 100%;
    height: auto;
}

/* Ajustes de tipografía generales */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family);
    font-weight: 700;
}

p {
    font-family: var(--font-family);
    font-weight: 400;
}

.highlight-red {
    color: var(--primary-color);
    font-weight: 500;
}

/* How it Works Section - Con emojis y estilo moderno */
.how-it-works {
    padding: 7rem 0;
    background-color: white;
}

.steps {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
}

.step {
    flex: 1;
    text-align: center;
    padding: 2.5rem 2rem;
    border-radius: var(--border-radius);
    background-color: var(--light-color);
    transition: var(--transition);
    border: 1px solid var(--gray-200);
}

.step:hover {
    transform: translateY(-10px);
    box-shadow: var(--box-shadow);
    border-color: transparent;
}

.step-icon {
    width: 90px;
    height: 90px;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gradient-green);
    border-radius: 50%;
    font-size: 2.5rem; /* Para emojis */
}

.step h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--dark-color);
    font-weight: 700;
}

.step h3::after {
    content: '';
    display: block;
    width: 40px;
    height: 3px;
    background: var(--gradient-green);
    margin: 0.75rem auto 0;
    border-radius: 3px;
}

.step p {
    color: var(--gray-600);
    font-size: 1.05rem;
}

/* Benefits Section - Con tarjetas modernas */
.benefits {
    padding: 7rem 0;
    background-color: var(--light-color);
    position: relative;
    overflow: hidden;
}

.benefits::before {
    content: '';
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(183, 255, 51, 0.05), rgba(219, 255, 153, 0.05));
    top: -100px;
    left: -200px;
    z-index: 0;
}

.benefits-container {
    display: flex;
    gap: 5rem;
    position: relative;
    z-index: 1;
}

.benefit-column {
    flex: 1;
}

.benefit-column h3 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    color: var(--dark-color);
    text-align: center;
    font-weight: 700;
    position: relative;
    display: inline-block;
}

.benefit-column h3::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--gradient-green);
    border-radius: 2px;
}

.benefits-list li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
    transition: var(--transition);
    border: 1px solid var(--gray-200);
}

.benefits-list li:hover {
    box-shadow: var(--box-shadow);
    transform: translateY(-5px);
    border-color: transparent;
}

.benefit-icon {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 1.5rem;
    background: var(--gradient-green);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem; /* Para emojis */
}

.benefit-text h4 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    color: var(--dark-color);
    font-weight: 700;
}

.benefit-text p {
    color: var(--gray-600);
    font-size: 1.05rem;
}

/* Explore Stores Section - Con tarjetas modernas */
.explore-stores {
    padding: 7rem 0;
    background-color: white;
}

.store-search {
    margin-bottom: 4rem;
}

.search-filters {
    display: flex;
    gap: 1rem;
    max-width: 800px;
    margin: 0 auto;
    background-color: var(--light-color);
    padding: 1.5rem;
    border-radius: var(--border-radius);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
}

.search-input, .category-select {
    flex: 1;
    padding: 1rem 1.25rem;
    border: 1px solid var(--gray-300);
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: 1rem;
}

.search-input:focus, .category-select:focus {
    outline: none;
    border-color: var(--secondary-color);
    box-shadow: 0 0 0 3px rgba(183, 255, 51, 0.2);
}

.stores-map {
    margin-bottom: 4rem;
}

.map-container {
    display: flex;
    height: 600px;
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--gray-200);
    box-shadow: var(--box-shadow);
}

.map {
    flex: 2;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.map-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-600);
    font-size: 1.2rem;
}

.stores-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2.5rem;
    margin-bottom: 4rem;
}

.store-card {
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-200);
}

.store-card:hover {
    box-shadow: var(--box-shadow);
    transform: translateY(-7px);
    border-color: transparent;
}

.store-image {
    height: 200px;
    overflow: hidden;
    position: relative;
}

.store-image::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(to top, rgba(0,0,0,0.3), transparent);
}

.store-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.store-card:hover .store-image img {
    transform: scale(1.05);
}

.store-info {
    padding: 1.75rem;
    flex-grow: 1;
}

.store-info h3 {
    font-size: 1.4rem;
    margin-bottom: 0.75rem;
    color: var(--dark-color);
    font-weight: 700;
}

.store-category {
    color: var(--dark-color);
    font-weight: 600;
    margin-bottom: 0.75rem;
    font-size: 0.95rem;
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: var(--gradient-green);
    border-radius: 50px;
}

.store-address {
    color: var(--gray-600);
    margin-bottom: 0.75rem;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
}

.store-address::before {
    content: '📍';
    margin-right: 0.5rem;
}

.store-rating {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.stars {
    color: var(--accent-color);
    margin-right: 0.5rem;
}

.rating-count {
    color: var(--gray-600);
    font-size: 0.9rem;
}

.store-card .btn {
    margin: 0 1.75rem 1.75rem;
}

.view-more {
    text-align: center;
}

/* FAQ Section - Con estilo moderno */
.faq {
    padding: 7rem 0;
    background-color: var(--light-color);
}

.faq-container {
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    margin-bottom: 1.5rem;
    border-radius: var(--border-radius);
    background-color: white;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
    border: 1px solid var(--gray-200);
    transition: var(--transition);
}

.faq-item:hover {
    box-shadow: var(--box-shadow);
    border-color: transparent;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.75rem;
    cursor: pointer;
    transition: var(--transition);
}

.faq-question:hover {
    background-color: rgba(183, 255, 51, 0.05);
}

.faq-question h3 {
    font-size: 1.25rem;
    color: var(--dark-color);
    font-weight: 600;
}

.faq-toggle {
    font-size: 1.5rem;
    color: var(--dark-color);
    transition: var(--transition);
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gradient-green);
    border-radius: 50%;
}

.faq-answer {
    padding: 0 1.75rem 1.75rem;
    color: var(--gray-600);
    display: none;
    font-size: 1.05rem;
    line-height: 1.7;
}

.faq-item.active .faq-toggle {
    transform: rotate(45deg);
    background: var(--gradient-red);
    color: white;
}

.faq-item.active .faq-answer {
    display: block;
}

/* Join Market Section - Con gradiente y estilo moderno */
.join-market {
    padding: 7rem 0;
    background-color: white;
    position: relative;
    overflow: hidden;
}

.join-market::before {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(183, 255, 51, 0.05), rgba(219, 255, 153, 0.05));
    bottom: -300px;
    right: -200px;
    z-index: 0;
}

.join-content {
    display: flex;
    align-items: center;
    gap: 5rem;
    position: relative;
    z-index: 1;
}

.join-text {
    flex: 1;
}

.join-text h2 {
    font-size: 2.75rem;
    font-weight: 800;
    margin-bottom: 1.75rem;
    color: var(--dark-color);
    line-height: 1.2;
    letter-spacing: -0.02em;
}

.join-text p {
    font-size: 1.2rem;
    color: var(--gray-600);
    margin-bottom: 2.5rem;
    line-height: 1.7;
}

.join-image {
    flex: 1;
    border-radius: var(--border-radius);
    overflow: hidden;
    transform: perspective(1000px) rotateY(5deg);
    transition: transform 0.5s ease;
}

.join-image:hover {
    transform: perspective(1000px) rotateY(0deg);
}

/* Footer Styles - Versión más compacta */
.footer {
    background-color: white;
    padding: 3rem 0 1.5rem; /* Reducido de 5rem a 3rem en la parte superior */
    border-top: 1px solid var(--gray-200);
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 2rem; /* Reducido de 3rem a 2rem */
}

.footer-logo {
    flex: 1;
    min-width: 250px;
}

.footer-logo img {
    height: 80px; /* Reducido de 200px a 80px */
    margin-bottom: 1rem; /* Reducido de 1.5rem a 1rem */
    border-bottom: 3px solid var(--primary-color);
    padding-bottom: 0.5rem; /* Reducido de 1rem a 0.5rem */
}

.footer-logo p {
    color: var(--gray-600);
    max-width: 300px;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}

.footer-column h4 {
    font-size: 1.1rem; /* Reducido de 1.25rem a 1.1rem */
    margin-bottom: 1rem; /* Reducido de 1.5rem a 1rem */
    color: var(--dark-color);
}

.footer-column ul li {
    margin-bottom: 0.5rem; /* Reducido de 0.75rem a 0.5rem */
}

.footer-column ul li a {
    color: var(--gray-600);
    transition: var(--transition);
}

.footer-column ul li a:hover {
    color: var(--primary-color);
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem; /* Reducido de 1rem a 0.5rem */
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.footer-bottom p {
    margin-bottom: 0;
}

.mayah-powered {
    font-size: 0.9rem;
    color: var(--gray-600);
}

.mayah-powered a {
    color: var(--primary-color);
    font-weight: 500;
    text-decoration: none;
    transition: var(--transition);
}

.mayah-powered a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 992px) {
    .hero .container,
    .join-content {
        flex-direction: column;
    }
    
    .hero-image,
    .join-image {
        order: -1;
        margin-bottom: 3rem;
    }
    
    .steps {
        flex-direction: column;
    }
    
    .step {
        margin-bottom: 2.5rem;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .benefits-container {
        flex-direction: column;
        gap: 4rem;
    }
    
    .section-title,
    .hero h1,
    .join-text h2 {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .header-content {
        position: relative;
    }
    
    .main-nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 1.5rem 0;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        display: none;
        border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
    
    .main-nav.active {
        display: block;
    }
    
    .main-nav ul {
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }
    
    .header-buttons {
        display: none;
    }
    
    .mobile-menu-toggle {
        display: block;
    }
    
    .search-filters {
        flex-direction: column;
        padding: 1.25rem;
    }
    
    .hero h1 {
        font-size: 2.25rem;
    }
    
    .section-title {
        font-size: 2rem;
        margin-bottom: 2.5rem;
    }
    
    .join-text h2 {
        font-size: 2rem;
    }
    
    .hero-description,
    .join-text p {
        font-size: 1.1rem;
    }
    
    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }
}

@media (max-width: 576px) {
    .hero {
        padding: 8rem 0 5rem;
    }
    
    .hero h1 {
        font-size: 2rem;
    }
    
    .hero-description {
        font-size: 1rem;
    }
    
    .section-title {
        font-size: 1.75rem;
    }
    
    .join-text h2 {
        font-size: 1.75rem;
    }
    
    .footer-content {
        flex-direction: column;
        gap: 3rem;
    }
    
    .footer-links {
        flex-direction: column;
        gap: 2.5rem;
    }
    
    .btn-large {
        padding: 0.875rem 1.75rem;
        font-size: 1rem;
    }
    
    .how-it-works,
    .benefits,
    .explore-stores,
    .faq,
    .join-market {
        padding: 5rem 0;
    }
}

/* JavaScript-related styles */
.faq-item.active .faq-answer {
    display: block;
}

.main-nav.active {
    display: block;
}

.mobile-menu-toggle.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.mobile-menu-toggle.active span:nth-child(2) {
    opacity: 0;
}

.mobile-menu-toggle.active span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
}

/* Animaciones y efectos adicionales */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.hero-content, .hero-image, .step, .benefit-column, .store-card, .faq-item, .join-text, .join-image {
    animation: fadeIn 0.8s ease-out forwards;
}

/* Diferentes tiempos de animación para crear un efecto escalonado */
.step:nth-child(2) { animation-delay: 0.2s; }
.step:nth-child(3) { animation-delay: 0.4s; }

.store-card:nth-child(2) { animation-delay: 0.15s; }
.store-card:nth-child(3) { animation-delay: 0.3s; }

.faq-item:nth-child(2) { animation-delay: 0.1s; }
.faq-item:nth-child(3) { animation-delay: 0.2s; }
.faq-item:nth-child(4) { animation-delay: 0.3s; }
.faq-item:nth-child(5) { animation-delay: 0.4s; }

/* Estilos adicionales para elementos con gradiente */
.gradient-badge {
    background: var(--gradient-green);
    color: var(--dark-color);
    padding: 0.25rem 0.75rem;
    border-radius: 50px;
    font-weight: 600;
    font-size: 0.9rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.gradient-badge::before {
    content: '✨';
}

/* Estilos para emojis destacados */
.emoji-highlight {
    font-size: 1.5em;
    margin-right: 0.5rem;
    vertical-align: middle;
}

/* Impact Statistics Section */
.impact-stats {
    padding: 6rem 0;
    background-color: var(--light-color);
    position: relative;
    overflow: hidden;
}

.impact-stats::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(var(--gray-200) 1px, transparent 1px);
    background-size: 20px 20px;
    opacity: 0.5;
    z-index: 0;
}

.impact-subtitle {
    text-align: center;
    font-size: 1.25rem;
    max-width: 800px;
    margin: -2rem auto 3.5rem;
    color: var(--gray-700);
}

.stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 4rem;
    position: relative;
    z-index: 1;
}

.stat-card {
    background-color: white;
    border-radius: var(--border-radius);
    padding: 2.5rem 2rem;
    text-align: center;
    flex: 1;
    min-width: 240px;
    max-width: 280px;
    box-shadow: var(--box-shadow);
    transition: var(--transition);
    border: 1px solid var(--gray-200);
}

.stat-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.stat-card.highlight {
    background: var(--gradient-green);
    border: none;
}

.stat-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.stat-number {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--dark-color);
    line-height: 1;
}

.stat-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--dark-color);
}

.stat-description {
    color: var(--gray-600);
    font-size: 1rem;
}

.impact-example {
    background-color: white;
    border-radius: var(--border-radius);
    padding: 3rem;
    box-shadow: var(--box-shadow);
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.impact-example h3 {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 2.5rem;
    color: var(--dark-color);
}

.example-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;
}

.example-card {
    flex: 1;
    max-width: 350px;
    padding: 2rem;
    border-radius: var(--border-radius);
    background-color: var(--gray-100);
    text-align: center;
}

.example-card.highlight {
    background: var(--gradient-green);
}

.example-card h4 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: var(--dark-color);
}

.example-price {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--dark-color);
}

.example-price.negative {
    color: var(--primary-color);
}

.example-price.positive {
    color: #2ecc71;
}

.example-detail {
    color: var(--gray-600);
    margin-bottom: 1.5rem;
    font-size: 0.95rem;
}

.example-total {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: var(--dark-color);
    padding-top: 1rem;
    border-top: 1px solid var(--gray-300);
}

.example-vs {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--gray-600);
}

.savings-highlight {
    text-align: center;
    background-color: var(--gray-100);
    padding: 1.5rem;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
}

.savings-amount {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary-color);
}

/* Responsive styles for impact section */
@media (max-width: 992px) {
    .example-container {
        flex-direction: column;
    }
    
    .example-card {
        max-width: 100%;
    }
    
    .example-vs {
        margin: 1rem 0;
    }
}

@media (max-width: 768px) {
    .impact-subtitle {
        font-size: 1.1rem;
    }
    
    .stat-card {
        min-width: 200px;
    }
    
    .impact-example {
        padding: 2rem 1.5rem;
    }
}

@media (max-width: 576px) {
    .stats-container {
        flex-direction: column;
        align-items: center;
    }
    
    .stat-card {
        max-width: 100%;
    }
}

/* Añadir estos estilos para incorporar el color rojo */

.highlight-red {
    color: var(--primary-color);
    font-weight: 500;
}

.red-icon {
    background: var(--gradient-red);
    box-shadow: 0 5px 15px rgba(199, 54, 54, 0.2);
}

.btn-secondary {
    background: var(--gradient-red);
    color: white;
    box-shadow: 0 4px 14px rgba(199, 54, 54, 0.3);
}

.btn-secondary:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(199, 54, 54, 0.4);
    filter: brightness(1.05);
}

.stat-card.red-highlight {
    background: var(--gradient-red);
    color: white;
    border: none;
}

.stat-card.red-highlight .stat-number,
.stat-card.red-highlight .stat-title {
    color: white;
}

.stat-card.red-highlight .stat-description {
    color: rgba(255, 255, 255, 0.9);
}

/* Modificar el estilo de las preguntas frecuentes */
.faq-item.active .faq-toggle {
    background: var(--gradient-red);
    color: white;
}

/* Añadir un acento rojo al footer */
.footer-logo img {
    border-bottom: 3px solid var(--primary-color);
    padding-bottom: 1rem;
}

/* Estilos para la sección del mapa */
.map-section {
    padding: 6rem 0;
    background-color: white;
}

.map-description {
    text-align: center;
    max-width: 700px;
    margin: 0 auto 3rem;
    color: var(--gray-600);
    font-size: 1.1rem;
}

.map-container {
    display: flex;
    gap: 2rem;
    height: 600px;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

#map {
    flex: 2;
    height: 100%;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.map-sidebar {
    flex: 1;
    background-color: white;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.map-search {
    display: flex;
    margin-bottom: 1.5rem;
}

.map-search input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
    font-size: 1rem;
}

.search-btn {
    background: var(--gradient-green);
    color: var(--dark-color);
    border: none;
    padding: 0 1rem;
    border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
    cursor: pointer;
}

.map-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.filter-btn {
    background-color: var(--gray-100);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: var(--transition);
}

.filter-btn.active {
    background: var(--gradient-green);
    color: var(--dark-color);
}

.stores-list {
    flex: 1;
    overflow-y: auto;
}

.store-card {
    padding: 1rem;
    border-bottom: 1px solid var(--gray-200);
    cursor: pointer;
    transition: var(--transition);
}

.store-card:hover {
    background-color: var(--gray-100);
}

.store-card h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.store-rating {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.rating-value {
    font-weight: 600;
}

.rating-stars {
    color: #FFB84D;
}

.store-type {
    font-size: 0.9rem;
    color: var(--gray-600);
    margin-bottom: 0.75rem;
}

.btn-view-store {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: var(--gradient-green);
    color: var(--dark-color);
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
}

/* Estilos para la ventana de información del mapa */
.store-info {
    padding: 0.5rem;
}

.store-info h3 {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.store-info .btn-view-store {
    margin-top: 0.5rem;
}

@media (max-width: 992px) {
    .map-container {
        flex-direction: column;
        height: auto;
    }
    
    #map {
        height: 400px;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
    
    .map-sidebar {
        border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
    
    .stores-list {
        max-height: 300px;
    }
}

/* Estilos adicionales para el mapa */
#map.loading {
    position: relative;
}

.map-error {
    padding: 2rem;
    text-align: center;
    color: var(--primary-color);
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.loading-stores {
    padding: 2rem;
    text-align: center;
    color: var(--gray-600);
}

.no-stores {
    padding: 2rem;
    text-align: center;
    color: var(--gray-600);
}

.store-status {
    font-size: 0.85rem;
    margin-bottom: 0.75rem;
}

.store-status.open {
    color: #28a745;
}

.store-status.closed {
    color: var(--primary-color);
}

.store-address {
    font-size: 0.9rem;
    color: var(--gray-600);
    margin-bottom: 0.5rem;
}

/* Estilos para la ventana de información de Google Maps */
.gm-style .gm-style-iw-c {
    padding: 12px !important;
    border-radius: var(--border-radius-sm) !important;
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style .gm-style-iw-t::after {
    background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%) !important;
}